// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-about-js": () => import("./../../../src/templates/about.js" /* webpackChunkName: "component---src-templates-about-js" */),
  "component---src-templates-artist-js": () => import("./../../../src/templates/artist.js" /* webpackChunkName: "component---src-templates-artist-js" */),
  "component---src-templates-artists-template-js": () => import("./../../../src/templates/artists-template.js" /* webpackChunkName: "component---src-templates-artists-template-js" */),
  "component---src-templates-commission-js": () => import("./../../../src/templates/commission.js" /* webpackChunkName: "component---src-templates-commission-js" */),
  "component---src-templates-commissions-js": () => import("./../../../src/templates/commissions.js" /* webpackChunkName: "component---src-templates-commissions-js" */),
  "component---src-templates-edition-js": () => import("./../../../src/templates/edition.js" /* webpackChunkName: "component---src-templates-edition-js" */),
  "component---src-templates-event-js": () => import("./../../../src/templates/event.js" /* webpackChunkName: "component---src-templates-event-js" */),
  "component---src-templates-home-js": () => import("./../../../src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-info-template-js": () => import("./../../../src/templates/info-template.js" /* webpackChunkName: "component---src-templates-info-template-js" */),
  "component---src-templates-news-article-js": () => import("./../../../src/templates/news-article.js" /* webpackChunkName: "component---src-templates-news-article-js" */),
  "component---src-templates-news-page-js": () => import("./../../../src/templates/news-page.js" /* webpackChunkName: "component---src-templates-news-page-js" */),
  "component---src-templates-privacy-policy-js": () => import("./../../../src/templates/privacy-policy.js" /* webpackChunkName: "component---src-templates-privacy-policy-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */),
  "component---src-templates-programme-template-js": () => import("./../../../src/templates/programme-template.js" /* webpackChunkName: "component---src-templates-programme-template-js" */),
  "component---src-templates-project-js": () => import("./../../../src/templates/project.js" /* webpackChunkName: "component---src-templates-project-js" */),
  "component---src-templates-projects-js": () => import("./../../../src/templates/projects.js" /* webpackChunkName: "component---src-templates-projects-js" */),
  "component---src-templates-records-js": () => import("./../../../src/templates/records.js" /* webpackChunkName: "component---src-templates-records-js" */),
  "component---src-templates-shop-js": () => import("./../../../src/templates/shop.js" /* webpackChunkName: "component---src-templates-shop-js" */),
  "component---src-templates-tickets-template-js": () => import("./../../../src/templates/tickets-template.js" /* webpackChunkName: "component---src-templates-tickets-template-js" */),
  "component---src-templates-workshops-template-js": () => import("./../../../src/templates/workshops-template.js" /* webpackChunkName: "component---src-templates-workshops-template-js" */)
}

